import { useEffect } from 'react';
import { generatePath, Redirect, Route } from 'react-router';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  useIonModal,
  useIonRouter,
} from '@ionic/react';
import { IonReactRouter } from "@ionic/react-router";
import { compassOutline, homeOutline, mapOutline, personCircleOutline } from 'ionicons/icons';
import queryString from 'query-string';
import { map } from 'lodash-es';
import { Capacitor } from '@capacitor/core';

import { useTranslation } from "react-i18next";
import { MediaPlayerProvider } from './contexts/MediaPlayerContext';
import { useLocale } from './contexts/LocaleContext';
import PrivateRoute from './components/PrivateRoute';

// Pages
import AppUrlListener from "./pages/AppUrlListener";
import CreatorProfilePage from "./pages/CreatorProfilePage";
import StoryMapPage from "./pages/StoryMapPage";
import StoryPage from "./pages/StoryPage";
import TourPage from "./pages/TourPage";
import ToursPage from "./pages/ToursPage";
import UserProfilePage from "./pages/UserProfilePage";
import { MixpanelUserIdenification } from "./contexts/MixpanelContext";
import LoginPage from "./pages/LoginPage";
import OnboardingPage from './pages/OnboardingPage';
import { getItemFromStorage } from './helpers/storage-helpers';
import useOnboardingStore from './stores/useOnboardingStore';
import useUIStore from './stores/useUIStore';
import SignUpPage from './pages/SignUpPage';
import SignUpConfirmationPage from './pages/SignUpConfirmationPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ResetPasswordConfirmationPage from './pages/ResetPasswordConfirmationPage';
import UpdateProfilePage from './pages/UpdateProfilePage';
import DeleteAccountPage from './pages/DeleteAccountPage';
import DeleteAccountConfirmationPage from './pages/DeleteAccountConfirmationPage';
import HomePage from './pages/HomePage';
import SightseeingSpotPage from './pages/SightseeingSpotPage';
import TourCollectionPage from './pages/TourCollectionPage';
import LanguageSelectorPage from './pages/LanguageSelectorPage';
import useGroupSharing from './hooks/useGroupSharing';
import {
  useGetViewedUserStoriesLazyQuery,
  useMeLazyQuery
} from './graphql/backend/__generated__/backend-graphql-sdk.generated';
import StartTourPage from './pages/StartTourPage';
import JoinGroupSharingPage from './pages/JoinGroupSharingPage';
import DownloadsPage from './pages/DownloadsPage';
import useNetwork from './hooks/useNetwork';
import useError from './hooks/useError';
import useNearTourStopForActiveTour from './hooks/useNearTourStopForActiveTour';
import SmartAppBanner from './components/SmartAppBanner';
import QrCodeTaskCompletionPage from './pages/QrCodeTaskCompletionPage';
import useNearStoryForExploreMode from './hooks/useNearStoryForExploreMode';
import StoriesListInExploreModeModal from './components/modals/StoriesListInExploreModeModal';
import useStoryExplorationStore from './stores/useStoryExplorationStore';
import useAuthStore from './stores/useAuthStore';
import useAnalyticsStore from './stores/useAnalyticsStore';
import useSupportedAppVersion from './hooks/useSupportedAppVersion';
import CreatedStoriesPage from './pages/CreatedStoriesPage';
import BarcodeScannerPage from './pages/BarcodeScannerPage';
import { isIosVersion } from './helpers/device-helpers';
import CreateTourPage from './pages/CreateTourPage';
import CreatedToursPage from './pages/CreatedToursPage';
import ToursMapPage from './pages/ToursMapPage';
import { CityProvider } from './contexts/CityContext';
import { OnboardingHintsProvider } from './contexts/OnboardingHintsContext';
import { TourCreationRequestProvider } from './contexts/TourCreationRequestContext';
import { StoryCreationRequestProvider } from './contexts/StoryCreationRequestContext';
import { OneSignalProvider } from './contexts/OneSignalContext';
import PremiumAccessPurchasingPage from './pages/PremiumAccessPurchasingPage';
import { TransactionProvider } from './contexts/TransactionContext';
import PromotionalCodePage from './pages/PromotionalCodePage';
import OrdersPage from './pages/OrdersPage';
import AuthFlowPage from './pages/AuthFlowPage';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { LocaleProvider } from './contexts/LocaleContext';
import useAppVersionFeatures from './hooks/useAppVersionFeatures';
import CitiesPage from './pages/CitiesPage';

const GuidableApp: React.FC = () => {
  useSupportedAppVersion();
  useAppVersionFeatures();
  useGroupSharing();
  useNearTourStopForActiveTour();
  useNearStoryForExploreMode();

  const router = useIonRouter();
  const { locale } = useLocale();
  const { t } = useTranslation();
  const { getNetworkStatus } = useNetwork();
  const { setCurrentUserWithRelatedData } = useAuth();
  const { handleBackendError } = useError();

  const [getMeQuery] = useMeLazyQuery();
  const [getViewedUserStoriesQuery] = useGetViewedUserStoriesLazyQuery();

  const isOnboardingOver = useOnboardingStore((state) => state.isOnboardingOver);
  const setIsOnboardingOver = useOnboardingStore((state) => state.setIsOnboardingOver);
  const isNavigationMenuVisible = useUIStore((state) => state.isNavigationMenuVisible);
  const nearestStoriesToExplore = useStoryExplorationStore((state) => state.nearestStoriesToExplore);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const trackStoryViews = useAnalyticsStore((state) => state.trackStoryViews);

  const [present, dismiss] = useIonModal(StoriesListInExploreModeModal, {
    router,
    onDismiss: () => dismiss(),
  });

  // get started user stories for authenticated users
  useEffect(() => {
    if (isAuthenticated) {
      const getViewedUserStories = async () => {
        await handleBackendError(async () => {
          const { data, error } = await getViewedUserStoriesQuery({
            fetchPolicy: 'no-cache',
          });

          if (error) return error;

          const viewedStoryIds = map(
            data?.userStory?.getViewedUserStories,
            (startedUserStory) => startedUserStory.datoStoryId
          );

          trackStoryViews(viewedStoryIds);
        });
      };

      getViewedUserStories();
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticated]
  );

  useEffect(() => {
    // open stories list in explore mode modal if new stories get added
    if (nearestStoriesToExplore?.length) {

      present({
        // set animated false for ios 17,because animation breaks the popup
        // on this ios version (https://github.com/ionic-team/ionic-framework/issues/27620)
        animated: !isIosVersion(17)
      });
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nearestStoriesToExplore]
  );

  useEffect(() => {
    // get if onboarding is over from the storage and set to state.
    // skip the onpoarding for the web version by setting isOnboardingOver to true.
    // determine the device language and set it to i18n if onboarding is not over.
    const checkIsOnboardingOver = async () => {
      // Temporary avoid displaying onboarding
      // TODO: Completely delete onboarding if it is not needed more
      setIsOnboardingOver(true);

      // const isOnboardingOver = await getItemFromStorage('isOnboardingOver');
      // const isWeb = isPlatform('mobileweb') || isPlatform('desktop');
      //
      // if (!isOnboardingOver) {
      //   const languageInfo = await Device.getLanguageCode();
      //   const nextLocale = languageInfo?.value === 'de' ? Locales.DE : Locales.EN;
      //   await i18n.changeLanguage(nextLocale);
      // }
      //
      // setIsOnboardingOver(isWeb || !!isOnboardingOver);
    };

    // check current user in local storage
    const checkMe = async () => {
      const me = await getItemFromStorage('me');
      const networkStatus = await getNetworkStatus();

      if (me) {
        if (networkStatus?.connected) {
          // update current user
          // if the current user is saved to the capacitor store
          // and the device is connected to the network
          await handleBackendError(async () => {
            const { data, error } = await
              getMeQuery();
            const me = data?.user?.me;
            setCurrentUserWithRelatedData(me, true);
            if (error) return error;
          });
        } else {
          // set user data saved in the capacitor store
          // if device doesn't connected to the network
          setCurrentUserWithRelatedData(me, true);
        }
      } else {
        setCurrentUserWithRelatedData(null);
      }
    };

    checkIsOnboardingOver();
    checkMe();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // wait to check if the onboarding is over
  if (typeof isOnboardingOver !== 'boolean') {
    return null;
  }

  if (!isOnboardingOver) {
    const returnTo = router.routeInfo.pathname + router.routeInfo.search;

    return (
      <IonRouterOutlet>
        {/* Onboarding Page */}
        <Route
          exact
          path="/:locale(de|en|fr|es)/onboarding"
          component={OnboardingPage}
        />
        <Route>
          <Redirect to={`/${locale}/onboarding?${queryString.stringify({ returnTo })}`}/>
        </Route>
      </IonRouterOutlet>
    );
  }

  return (
    <IonTabs>
      {/* Main Content */}
      <IonRouterOutlet>
        {/* Home Page */}
        <Route exact path="/:locale(de|en|fr|es)/:tab(home)" component={HomePage} />

        {/* Home Page */}
        <Route exact path="/:locale(de|en|fr|es)/:tab(home)/cities" component={CitiesPage} />

        {/* Tours Page */}
        <Route exact path="/:locale(de|en|fr|es)/:tab(tours)" component={ToursPage} />

        {/* Tour Page */}
        <Route
          exact
          path="/:locale(de|en|fr|es)/:tab(tours)/:tourSlug"
          component={TourPage}
        />

        {/* Start Tour Page */}
        <Route
          exact
          path="/:locale(de|en|fr|es)/:tab(tours)/:tourSlug/start-tour"
          component={StartTourPage}
        />

        {/* Create Tour Page */}
        <Route exact path="/:locale(de|en)/:tab(tours)/create" component={CreateTourPage} />

        {/* Tours Map Page */}
        <Route exact path="/:locale(de|en|fr|es)/:tab(tours)/map" component={ToursMapPage} />

        {/* Join Group Sharing Page */}
        <Route
          exact
          path="/:locale(de|en|fr|es)/group-sharing/join"
          component={JoinGroupSharingPage}
        />

        {/* Stories Page */}
        <Route
          exact
          path="/:locale(de|en|fr|es)/:tab(stories)"
          component={StoryMapPage}
        />

        {/* Story Page */}
        <Route exact path="/:locale(de|en|fr|es)/:tab(story)" component={StoryPage} />

        {/* Creator Profile Page */}
        <Route
          exact
          path="/:locale(de|en|fr|es)/:tab(stories)/creators/:creatorProfileUsername"
          component={CreatorProfilePage}
        />

        {/* User Profile Page */}
        <Route
          exact
          path="/:locale(de|en|fr|es)/:tab(user-profile)"
          component={UserProfilePage}
        />

        {/* Update Profile Page */}
        <PrivateRoute exact path="/:locale(de|en|fr|es)/:tab(user-profile)/edit" component={UpdateProfilePage}/>

        {/* Downloads Page */}
        <PrivateRoute exact path="/:locale(de|en|fr|es)/:tab(user-profile)/downloads" component={DownloadsPage} />

        {/* Orders */}
        <PrivateRoute exact path="/:locale(de|en|fr|es)/:tab(user-profile)/orders" component={OrdersPage} />

        {/* Created Stories Page */}
        <PrivateRoute exact path="/:locale(de|en|fr|es)/:tab(user-profile)/created-stories" component={CreatedStoriesPage} />

        {/* Created Tours Page */}
        <PrivateRoute exact path="/:locale(de|en)/:tab(user-profile)/created-tours" component={CreatedToursPage} />

        {/* Delete Account Page */}
        <PrivateRoute exact path="/:locale(de|en|fr|es)/:tab(user-profile)/delete-account" component={DeleteAccountPage} />

        {/* Delete Account Confirmation Page */}
        <PrivateRoute
          exact
          path="/:locale(de|en|fr|es)/:tab(user-profile)/delete-account/confirmation"
          component={DeleteAccountConfirmationPage}
        />

        {/* Promotional Code Page (available only for web version) */}
        {Capacitor.isNativePlatform() ?
          <Route exact path="/:locale(de|en|fr|es)/:tab(user-profile)/promotional-code">
            <Redirect to={`/${locale}/user-profile`}/>
          </Route> :
          <PrivateRoute
            exact
            path="/:locale(de|en|fr|es)/:tab(user-profile)/promotional-code"
            component={PromotionalCodePage}
          />}

        {/* Auth Flow Page */}
        <Route
          exact
          path="/:locale(de|en|fr|es)/auth"
          component={AuthFlowPage}
        />

        {/* Login Page */}
        <Route exact path="/:locale(de|en|fr|es)/login" component={LoginPage} />

        {/* Sign Up Page */}
        <Route exact path="/:locale(de|en|fr|es)/sign-up" component={SignUpPage} />

        {/* Sign Up Confirmation Page */}
        <Route exact path="/:locale(de|en|fr|es)/sign-up/confirmation" component={SignUpConfirmationPage} />

        {/* Reset Password Page */}
        <Route exact path="/:locale(de|en|fr|es)/reset-password" component={ResetPasswordPage} />

        {/* Reset Password Confirmation Page */}
        <Route exact path="/:locale(de|en|fr|es)/reset-password/confirmation" component={ResetPasswordConfirmationPage} />

        {/* Sightseeing Spot Page */}
        <Route exact path="/:locale(de|en|fr|es)/sightseeing-spots/:sightseeingSpotId" component={SightseeingSpotPage} />

        {/* Tour Collection Page */}
        <Route exact path="/:locale(de|en|fr|es)/tour-collections/:tourCollectionId" component={TourCollectionPage} />

        {/* Language Selector Page */}
        <Route exact path="/language-selector" component={LanguageSelectorPage} />

        {/* QR Code Completion Task Page */}
        <Route exact path="/:locale(de|en|fr|es)/qr-code-task-completion/:taskReferenceId" component={QrCodeTaskCompletionPage} />

        {/* Barcode Scanner Page */}
        <Route exact path="/:locale(de|en|fr|es)/barcode-scanner" component={BarcodeScannerPage} />

        {/* Premium Access Purchasing Page */}
        <Route exact path="/:locale(de|en|fr|es)/premium-access-purchasing" component={PremiumAccessPurchasingPage} />

        {/* Localized home route, redirect to home page */}
        <Route exact path="/:locale(de|en|fr|es)/">
          <Redirect to={`/${locale}/home`} />
        </Route>

        {/* Root route, redirect to home page */}
        <Route exact path="/">
          <Redirect to={`/${locale}/home`}/>
        </Route>
      </IonRouterOutlet>

      {/* Bottom Tab Bar */}
      <IonTabBar slot="bottom" style={{ display: isNavigationMenuVisible ? 'flex': 'none' }}>
        {/* Home tab */}
        <IonTabButton
          tab="home"
          href={generatePath(`/:locale(de|en|fr|es)/home`, { locale })}
        >
          <IonIcon icon={homeOutline} />
          <IonLabel>{t("tabBar.home")}</IonLabel>
        </IonTabButton>

        {/* Tours tab */}
        <IonTabButton
          tab="tours"
          href={generatePath(`/:locale(de|en|fr|es)/tours`, { locale })}
        >
          <IonIcon icon={compassOutline} />
          <IonLabel>{t("tabBar.tours")}</IonLabel>
        </IonTabButton>

        {/* Explore tab */}
        <IonTabButton
          tab="tour"
          href={generatePath("/:locale(de|en|fr|es)/stories", { locale })}
        >
          <IonIcon icon={mapOutline} />
          <IonLabel>{t("tabBar.explore")}</IonLabel>
        </IonTabButton>

        {/* User profile tab */}
        <IonTabButton
          tab="user-profile"
          href={generatePath("/:locale(de|en|fr|es)/user-profile", { locale })}
        >
          <IonIcon icon={personCircleOutline} />
          <IonLabel>{t("tabBar.userProfile")}</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <MixpanelUserIdenification />
      <SmartAppBanner />

      <AppUrlListener />

      <LocaleProvider>
        <AuthProvider>
          <MediaPlayerProvider>
            <CityProvider>
              <OneSignalProvider>
                <TransactionProvider>
                  <TourCreationRequestProvider>
                    <StoryCreationRequestProvider>
                      <OnboardingHintsProvider>
                        <GuidableApp />
                      </OnboardingHintsProvider>
                    </StoryCreationRequestProvider>
                  </TourCreationRequestProvider>
                </TransactionProvider>
              </OneSignalProvider>
            </CityProvider>
          </MediaPlayerProvider>
        </AuthProvider>
      </LocaleProvider>
    </IonReactRouter>
  </IonApp>
);

export default App;
